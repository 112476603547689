/* Load the custom Rabar font */
@font-face {
  font-family: 'Rabar';
  src: url('./fonts/Rabar_043.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Main Container */
.export-history-container {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  max-width: 900px;
  height: 700px;
  margin: 70px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: 'Rabar', sans-serif; /* Apply custom font globally */
}

/* Title Styling */
.export-history-container h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: 'Rabar', sans-serif; /* Apply custom font to title */
}

/* Search bar container for icon and input field */
.search-bar-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto 20px;
  position: relative;
}

.search-bar-container input {
  font-family: 'Rabar', sans-serif; /* Apply font to input */
}

/* Search bar input field styling */
.search-bar {
  width: 100%;
  padding: 10px 40px 10px 15px;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: 'Rabar', sans-serif; /* Apply custom font to the search bar */
}

/* Search icon styling inside the search bar */
.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
  color: #999;
}

/* Table Header Styling */
.table-header {
  display: flex;
  width: 100%;
  background-color: rgb(25, 75, 58);
  color: white;
  font-size: 1.1rem;
  padding: 12px;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Rabar', sans-serif; /* Apply custom font to table header */
}

/* Table Wrapper with Scroll */
.table-wrapper {
  flex-grow: 1;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-top: none;
  margin-bottom: 20px;
  position: relative;
}

/* Sticky Table Header */
.export-history-container thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: rgb(25, 75, 58); /* Ensure header stays visually separated */
  color: white;
}

/* Table Styling */
.export-history-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0;
  table-layout: fixed;
}

.export-history-container th,
.export-history-container td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
  font-size: 0.95rem;
  font-family: 'Rabar', sans-serif; /* Apply custom font to table content */
}

/* Button Styling */
.return-button,
.back-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px auto 0;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Rabar', sans-serif; /* Apply custom font to the buttons */
}

.return-button:hover,
.back-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.back-button {
  background-color: #1c137c;
}

.back-button:hover {
  background-color: #26229c;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .export-history-container {
    padding: 20px;
    height: auto;
    max-width: 90%;
  }

  .search-bar-container {
    max-width: 100%;
  }

  .return-button,
  .back-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .table-header {
    font-size: 0.9rem;
    padding: 8px;
  }

  .export-history-container table th,
  .export-history-container table td {
    padding: 8px;
    font-size: 0.9rem;
  }
}
