

/* Background pattern styling */
.login-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('dyarcompanybackground.png'); /* Replace with the correct path to your background image */
  background-size: cover;
  background-position: center;
  opacity: 0.1; /* Low opacity to ensure the background doesn't distract too much */
  z-index: 0; /* Make sure the background stays behind the form */
}

/* Form styling */
.login-form-container {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 250px;
  max-width: 350px;
  position: relative;
  z-index: 1; /* Ensure the form is above the background */
}

/* Title styling */
.login-title {
  text-align: center;
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Input styling */
.login-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.login-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Button styling */
.login-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.login-button:active {
  background-color: #003d7a;
}

/* Error and success message styling */
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.success-message {
  color: green;
  font-size: 0.9rem;
  margin-bottom: 10px;
  margin-left: 125px;
}

/* Responsive design */
@media (max-width: 480px) {
  .login-form-container {
    padding: 20px;
  }

  .login-title {
    font-size: 1.5rem;
  }

  .login-input, .login-button {
    font-size: 0.9rem;
  }

  .login-input {
    padding: 10px;
  }

  .login-button {
    padding: 10px;
  }
}
