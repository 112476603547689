@font-face {
  font-family: 'RabarFont';
  src: url('./fonts/Rabar_043.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* General styles */
body {
  font-family: 'RabarFont', Arial, sans-serif;
  background: url('/public/dyarcompanybackground.png') center center fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
}

html, #root {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  max-width: 900px;
  padding: 20px;
  box-sizing: border-box;
}

.form-container {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 68, 255, 0.3);
  padding: 20px;
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin-bottom: 20px;
}

/* Language switcher and buttons */
.language-switcher {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.language-button {
  margin: 0 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #84aa9b;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 14px;
  font-family: 'RabarFont', Arial, sans-serif;
}

.language-button.selected {
  background-color: #1c137c;
}

.language-button:hover {
  background-color: #1c8f97;
}

.language-button.selected:hover {
  background-color: #1c137c;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}

.form-group input {
  margin: 5px;
  padding: 10px;
  font-size: 16px;
  width: calc(50% - 12px);
  box-sizing: border-box;
  border: 2px solid #1c137c;
  border-radius: 4px;
  transition: border-color 0.3s;
  font-family: 'RabarFont', Arial, sans-serif;
}

.rtl .form-group input {
  text-align: right;
}

.form-group input:focus {
  border-color: #007bff;
}

/* Add Listing, Export to Excel Buttons */
.add-listing, .export-to-excel {
  width: 100%;
  padding: 12px 24px;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 10px;
  font-family: 'RabarFont', Arial, sans-serif;
}

.add-listing {
  background-color: #1c137c;
}

.add-listing:hover {
  background-color: #26229c;
  transform: scale(1.05);
}

.export-to-excel {
  background-color: #03312e;
}

.export-to-excel:hover {
  background-color: #218838;
  transform: scale(1.05);
}

/* Undo button styling */
.undo-button {
  margin-top: 10px;
  padding: 12px 24px;
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #ff5c5c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'RabarFont', Arial, sans-serif;
}

.undo-button:hover {
  background-color: #ff3b3b;
  transform: scale(1.05);
}

/* Exchange Rate Styling */
.exchange-rate-container {
  margin: 5px;
  padding: 10px;
  font-size: 16px;
  width: calc(50% - 12px);
  box-sizing: border-box;
  border: 2px solid #333333;
  border-radius: 4px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'RabarFont', Arial, sans-serif;
}

.exchange-rate-container p {
  font-size: 16px;
  color: #000000;
  margin: 0;
}

/* Table and header styles */
.table-container {
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  font-family: 'RabarFont', Arial, sans-serif;
}

.rtl .table-container table th {
  text-align: right;
}

th {
  background-color: #03312e !important;
  color: white !important;
}

tr.new-listing td {
  background-color: #46beb8 !important;
  color: white !important;
  border: 1px solid #ddd;
}

tr.new-listing:hover td {
  background-color: #03312e !important;
  color: white !important;
  border: 1px solid #ddd;
}

/* Autocomplete suggestions */
/* Ensure the form group positions the suggestions right under the Name and Code field */
/* Ensure form-group has relative positioning */
.form-group {
  position: relative;
  z-index: 1;
}

/* Autocomplete suggestions */
.autocomplete-suggestions {
  position: absolute; /* Ensure it's positioned relative to the input */
  top: 50%; /* Move it up slightly by 1 box (adjust based on your form's layout) */
  left: 0;
  right: 0;
  background-color: #1c137c;
  border-left: 4px solid rgb(0, 0, 0);
  border-right: 4px solid rgb(224, 0, 0);
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  margin: 5px 0 0 0; /* Add space between input and dropdown */
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  animation: dropdownAnimation 0.3s ease;
}

.suggestion-item {
  padding: 8px 10px;
  cursor: pointer;
  color: white;
}

.suggestion-item:hover {
  background-color: #0056b3;
}

@keyframes dropdownAnimation {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


/* User Info and Dropdown Styles */
.user-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 10px;
  width: 150px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1002;
}

.user-info svg {
  margin-right: 5px;
  cursor: pointer;
}

/* Media queries for responsiveness */
@media (max-width: 480px) {
  .container {
      max-width: 100%;
      padding: 10px;
  }

  .form-container {
      padding: 15px;
  }

  .language-button {
      padding: 8px 15px;
      font-size: 12px;
  }

  th:nth-child(1),
  th:nth-child(2) {
      width: 150px !important;
  }
}
